.trainer_profile_image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: 5px solid #3ee7ff;
    object-fit: cover;
  }

  .trainer_list_container {
    min-height: 70vh;
  }

  .archive_btn {
    font-size: 10px;
    padding: 8px;
    background: none;
    border: 1px solid #06bed8;
    color: #06bed8;
  }

  .archive_btn:hover {
    border: 1px solid #06bed8 !important;
    color: #06bed8;
  }
  

  @media screen and(min-width: 320px) {
    .trainer_profile_image {
        width: 150px;
        height: 150px;
      }
  }

  @media screen and (min-width: 576px) {
    .trainer_profile_image {
      width: 120px;
      height: 120px;
      min-width: 120px;
      min-height: 120px;
    }
}

@media screen and (min-width: 768px) {
    .trainer_profile_image {
      width: 125px;
      height: 125px;
      min-width: 125px;
      min-height: 125px;
    }
}

@media screen and (min-width: 992px) {
    .trainer_profile_image {
      width: 150px;
      height: 150px;
      min-height: 150px;
      min-width: 150px;
    }
}

@media screen and (min-width: 1200px) {
    .trainer_profile_image {
      width: 150px;
      height: 150px;
      min-width: 150px;
      min-height: 150px;
    }
}