/* .image {
  background-image: url("/src/assets/images/backgroundImage.png");
  background-size: 60% 100%;
  background-repeat: no-repeat;
  background-position-x: 80%;
} */

.modal-body-style {
  min-height: 350px;
}

.contentWrapper {
  max-height: 70vh;
  overflow: auto;
}

.nameText {
  font-size: 22px;
  font-weight: 900;
  color: #000;
}

.descriptionText {
  color: #57545f;
  font-size: 12px;
}

.overlay-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.63);
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.optionContainer {
  width: 100%;
  height: 70%;
  color: #000;
  border: 1px solid #ced4da;
}

.optionTick {
  width: 25px;
  height: 25px;
}

.height100px {
  height: 100px;
}

.accordion-header button {
  padding: 10px 5px !important;
}

.accordion-header :focus {
  box-shadow: none;
  background-color: transparent;
  color: black;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: transparent;
  box-shadow: none;
}

.accordion-body {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 8px;
  padding-top: 0;
}

.optionSelected {
  border: 1px solid #06bed8;
  color: #06bed8;
}

.element-wrapper {
  width: 90%;
  box-shadow: -1px 2px 12px #cecdcd;
  padding: 10px 5px;
}

.why-heading {
  font-size: 18px;
  font-weight: bold;
  color: #06bed8;
}

.why-description {
  font-size: 14px;
  color: black;
}

.image {
  background-image: url("/src/assets/images/backgroundImage.png");
  background-size: 1200px 900px;
  background-repeat: no-repeat;
  background-position-x: 50%;
}
.wsm {
  width: 32% !important;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .wsm {
    width: 50% !important;
  }
  .image {
    background-image: url("/src/assets/images/backgroundImage.png");
    background-size: 1200px 900px;
    background-repeat: no-repeat;
    background-position-x: 70%;
  }
}

hr{
  border: 3px solid #d4f4f7;
}

@media (min-width: 0px) and (max-width: 767.98px) {
  .wsm {
    width: 100% !important;
  }
  .image {
    background-image: none;
  }
}

.tp-trainer-profile-image {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid #3ee7ff;
  object-fit: cover;
}

.tp-trainer-title span {
  font-size: 20px;
}

.tp-description {
  font-size: 12px;
}

.tp-trainer-title img {
  margin-left: 10px;
  vertical-align: text-bottom;
}

.tp-ratings .star-ratings svg {
  vertical-align: text-bottom;
}

.tp-location {
  font-size: 14px;
}

.tp-location span {
  margin-left: 5px;
}

.tp-categories {
  margin-top: 20px;
}

.tp-categories-title span {
  font-size: 14px;
}

.tp-categories-title img {
  width: 12px;
}

.tp-bio p {
  margin-top: 10px;
  font-size: 14px;
}

.tpCategoryChipsContainer span {
  padding: 6px 15px;
  color: #000;
  background-color: #d4f4f7;
  margin-right: 10px;
  font-size: 12px;
  display: inline-block;
  border-radius: 6px;
  margin-top: 10px;
}

.progress.rating-bar {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.75rem;
  --bs-progress-bg: #e9ecef;
  --bs-progress-border-radius: 0.375rem;
  --bs-progress-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #06bed8;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: 16px;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 10px;
  border: 1px solid #06bed8;
  margin-top: 8px;
}

.tp-ratting-text {
  font-size: 72px;
}

.tp-ratting-out-of {
  font-size: 12px;
}

.tp-w-80 {
  width: 80%;
}

.tp-w-60 {
  width: 60%;
}

.tp-w-40 {
  width: 40%;
}

.tp-w-20 {
  width: 20%;
}

.client-name-date {
  font-size: 12px;
}
.review-text {
  font-size: 14px;
}

.filter-icon {
  max-width: 25px;
}

.tp-bank-details {
  margin-top: 20px;
}

.option-container {
  width: 100%;
  height: 70%;
  color: #000;
  border: 1px solid #ced4da;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .tp-trainer-profile-image {
    width: 150px;
    height: 150px;
  }
}

@media screen and(max-width: 475px) {
  .tp-ratting-out-of {
    font-size: 10px;
  }
}

@media screen and (max-width: 390px) {
  .tp-ratting-text {
    font-size: 24px;
  }
  .ratings-container {
    max-width: 8.33333333% !important;
  }
}

/* .image{
  background-image: url('/src/assets/images/backgroundImage.png');
  background-size: 70% 120%;
  background-repeat: no-repeat;
  background-position-x: 80%;
}

@media ( min-width: 768px ) and (max-width: 991.98px) { 
  .w-sm{
      width: 50%!important;
  }
  .image{
      background-image: url('/src/assets/images/backgroundImage.png');
      background-size: 70% 120%;
      background-repeat: no-repeat;
      background-position-x: 70%;
  }
}

@media ( min-width: 0px ) and (max-width: 767.98px) { 
  .w-sm{
      width: 100%!important;
  }
  .image{
      background-image: url('/src/assets/images/backgroundImage.png');
      background-size: 80% 90%;
      background-repeat: no-repeat;
      background-position-x: 90%;
  }
} */
