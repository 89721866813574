.input-label {
  font-size: 16px;
}
.already-account {
  font-size: 12px;
}

.forgot-password {
  font-size: 12px;
}

.social-login-btn {
  border: none;
  background: none;
  padding: 0; 
  margin: 0;
  margin-top: 5px;
}
.overlay{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255,255,255,0.63);
  z-index: 2; 
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
