/* .image {
  background-image: url("/src/assets/images/backgroundImage.png");
  background-size: 60% 100%;
  background-repeat: no-repeat;
  background-position-x: 80%;
} */

.modal-body-style {
  min-height: 350px;
}

.contentWrapper {
  max-height: 70vh;
  overflow: auto;
}

.nameText {
  font-size: 22px;
  font-weight: 900;
  color: #000;
}

.descriptionText {
  color: #57545f;
  font-size: 12px;
}

.overlay-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.63);
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.optionContainer {
  width: 100%;
  height: 70%;
  color: #000;
  border: 1px solid #ced4da;
}

.optionTick {
  width: 25px;
  height: 25px;
}

.height100px {
  height: 100px;
}

.accordion-header button {
  padding: 10px 5px !important;
}

.accordion-header :focus {
  box-shadow: none;
  background-color: transparent;
  color: black;
}

.accordion-button:not(.collapsed) {
  color: black;
  background-color: transparent;
  box-shadow: none;
}

.accordion-body {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 8px;
  padding-top: 0;
}

.optionSelected {
  border: 1px solid #06bed8;
  color: #06bed8;
}

.element-wrapper {
  width: 90%;
  box-shadow: -1px 2px 12px #cecdcd;
  padding: 10px 5px;
}

.why-heading {
  font-size: 18px;
  font-weight: bold;
  color: #06bed8;
}

.why-description {
  font-size: 14px;
  color: black;
}

/* .image {
  background-image: url("/src/assets/images/backgroundImage.png");
  background-size: 1200px 900px;
  background-repeat: no-repeat;
  background-position-x: 50%;
} */
.wsm {
  width: 32% !important;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .wsm {
    width: 50% !important;
  }
  /* .image {
    background-image: url("/src/assets/images/backgroundImage.png");
    background-size: 1200px 900px;
    background-repeat: no-repeat;
    background-position-x: 70%;
  } */
}

@media (min-width: 0px) and (max-width: 767.98px) {
  .wsm {
    width: 100% !important;
  }
  .image {
    background-image: none;
  }
}

/* .image{
  background-image: url('/src/assets/images/backgroundImage.png');
  background-size: 70% 120%;
  background-repeat: no-repeat;
  background-position-x: 80%;
}

@media ( min-width: 768px ) and (max-width: 991.98px) { 
  .w-sm{
      width: 50%!important;
  }
  .image{
      background-image: url('/src/assets/images/backgroundImage.png');
      background-size: 70% 120%;
      background-repeat: no-repeat;
      background-position-x: 70%;
  }
}

@media ( min-width: 0px ) and (max-width: 767.98px) { 
  .w-sm{
      width: 100%!important;
  }
  .image{
      background-image: url('/src/assets/images/backgroundImage.png');
      background-size: 80% 90%;
      background-repeat: no-repeat;
      background-position-x: 90%;
  }
} */
