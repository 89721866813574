.package_card {
  box-shadow: 0px 3px 12px #a4a4a442;
  min-height: 240px;
}
.package_card_header {
  background-color: transparent;
  width: 95%;
  margin: auto;
  padding-left: 0px;
  padding-right: 0px;
}
.package_list_item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}
.package_name {
  font-size: 14px;
}
.package_price_per_session {
  font-size: 12px;
}
.purchase_btn {
  font-size: 12px;
  padding: 5px 10px;
}
