.smat-user-avatar {
  border-radius: 50%;
}

.smat-user-avatar img {
  border: 3px solid;
  object-fit: cover;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.smat-user-avatar span {
  font-size: 14px;
}

.dropdown-toggle.nav-link::after {
  display: none;
}

.nav-link {
  color: black
}

.nav-link-item {
  color: black;
  text-decoration: none !important;
}

.nav-link-item:hover {
  color: black !important;
}


.nav-app-links,
.nav-location-phone-avatar,
.nav-menus-items {
  display: none;
}

.user-dropdown > div {
  left: -50px !important;
}

#responsive-navbar-nav{
  position: absolute;
  width: 100%;
  padding: 19px;
  background: rgb(255, 255, 255);
  top: 55px;
  left: 0px;
  border-bottom: 1px solid #0000002e;
  border-radius: 0 0 10px 10px;
  box-shadow: 0px 5px 10px 0px #0000002e;
}

@media screen and (min-width: 992px) {

  .nav-app-links,
  .nav-location-phone-avatar,
  .nav-menus-items {
    display: flex;
  }

  #responsive-navbar-nav,
  .nav-location-phone-avatar-second {
    display: none !important;
  }

}
