.custom-package-code-btn,
.custom-package-code-input {
  font-size: 14px;
}
.package-card {
  box-shadow: 0px 3px 12px #a4a4a442;
  min-height: 275px;
}
.package-card-header {
  background-color: transparent;
  width: 95%;
  margin: auto;
  padding-left: 0px;
  padding-right: 0px;
}
#package-accordian {
  border: none !important;
  --bs-accordion-border-width: 0px !important;
  --bs-accordion-border-radius: 0px !important;
  --bs-accordion-inner-border-radius: 0px !important;
  --bs-accordion-btn-focus-border-color: none !important;
  --bs-accordion-btn-focus-box-shadow: 0px !important;
  --bs-accordion-body-padding-x: 0px !important;
  --bs-accordion-body-padding-y: 0px !important;
  --bs-accordion-active-color: none !important;
  --bs-accordion-active-bg: none !important;
  width: 100%;
}
#package-accordian .accordion-button::after {
  margin-left: 10px;
}
#package-accordian .accordion-button {
  color: #06bed8;
}
#package-accordian .accordion-collapse {
  width: 100%;
}
.pricing-and-package-container {
  min-height: 80vh;
}
.package-list-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: none;
  padding-left: 0px;
  padding-right: 0px;
}
.package-name {
  font-size: 14px;
}
.package-price-per-session {
  font-size: 12px;
}
.purchase-btn {
  font-size: 12px;
  padding: 5px 10px;
}

.desktop-package {
  display: flex;
}

.mobile-package {
  display: none;
}

.button {
  height: fit-content;
}

.service-row {
  justify-content: center;
}
.package-heading-col {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 991px) {
  .desktop-package {
    display: none;
  }
  .mobile-package {
    display: flex;
  }
  .extra-gap-col {
    display: none;
  }
  .service-row {
    justify-content: flex-start;
    margin-top: 20px;
  }
  .package-heading-col {
    justify-content: start;
  }
}
