.icon {
  width: 25px;
  margin-left: 10px;
}

.icon_flexible {
    width: 25px;
  }

.container {
  width: 95%;
  height: 95%;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.day-wrapper div {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.day-wrapper div > img {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.day-wrapper {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 20px;
}

.flexible_container{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
}
.flexible_row {
  flex: 1;
}

.options_container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
    flex:1;
}

.option{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.preferred_time_row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    align-items: baseline;    
}

.time_input{
    border: none;
}

.time_input :focus-visible{
    border: none !important;
}

@media screen and (max-width: 475px) {
  .day-wrapper {
    font-size: 11px;
  }
}