.colors>.btn {
  background-color: white;
  color: #6c757d;
  text-align: inherit;
  border: 1px solid #ced4da;
}

.colors>.btn:hover {
  background-color: white;
  color: #6c757d;
}

.colors>.btn:focus {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

.colors>div {
  width: 100%;
}

.success-card {
  border: none;
  height: 50%;
  margin: 5%;
}

.success-card-body {
  /* margin: 20%; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.success-card-title {
  bottom: 0;
}

.contentWrapper {
  /* max-height: 70vh; */
  min-height: 70vh;
  overflow: auto;
}

.name-text{
  font-size: 20px;
  font-weight: bold;
  color: #57545f;
}

.description-text{
  color: #57545f;
  font-size: 14px;
}

.optionContainer {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-between ;
  align-items: center;
  flex-direction: row;
  border-width: 1;
  overflow: hidden;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px 5px;
  cursor: pointer;
}

.optionSelected {
  border: 1px solid #06bed8;
  color: #06bed8;
}

.option-tick {
  width: 25px;
}

.option-tick-selected {
  width: 26px;
}

.height-100px{
    height: 100px;
}

.accordion-header button{
    padding: 10px 5px !important;
}

.accordion-header :focus{
    box-shadow: none;
    background-color: transparent;
    color: black;
}

.accordion-button:not(.collapsed){
    color: black;
    background-color: transparent;
    box-shadow: none;
}

.accordion-body{
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 8px;
    padding-top: 0;
}

.element-wrapper{
  width: 90%;
  box-shadow: -1px 2px 12px #cecdcd;
  padding: 10px 5px;
}

.why-heading{
  font-size: 18px;
  font-weight: bold;
  color: #06bed8;
}

.why-description{
  font-size: 14px;
  color: black;
}

.quick-broadcast-header{
  display: flex;
}

.broadcast-header-image{
  position: fixed;
}

.broadcast-header-heading{
  margin: auto;
}

@media (max-width: 450px) {
  .broadcast-header-heading{
    margin: auto;
    padding: 20px 0 0;
    font-size: 32px;
  }

  .quick-broadcast-header{
    display: block;
    text-align: center;
  }

  .broadcast-header-image{
    position: inherit;
  }
}