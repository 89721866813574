.modal-body-style {
  min-height: 350px;
}

.contentWrapper {
  /* max-height: 70vh; */
  min-height: 70vh;
  overflow: auto;
}

.name-text{
  font-size: 20px;
  font-weight: bold;
  color: #57545f;
}

.description-text{
  color: #57545f;
  font-size: 14px;
}

.overlay-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.63);
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.optionContainer {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-between ;
  align-items: center;
  flex-direction: row;
  border-width: 1;
  overflow: hidden;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px 5px;
  cursor: pointer;
}

.optionSelected {
  border: 1px solid #06bed8;
  color: #06bed8;
}

.option-tick {
  width: 25px;
}

.option-tick-selected {
  width: 26px;
}

.height-100px{
    height: 100px;
}

.accordion-header button{
    padding: 10px 5px !important;
}

.accordion-header :focus{
    box-shadow: none;
    background-color: transparent;
    color: black;
}

.accordion-button:not(.collapsed){
    color: black;
    background-color: transparent;
    box-shadow: none;
}

.accordion-body{
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 8px;
    padding-top: 0;
}

.element-wrapper{
  width: 90%;
  box-shadow: -1px 2px 12px #cecdcd;
  padding: 10px 5px;
}

.why-heading{
  font-size: 18px;
  font-weight: bold;
  color: #06bed8;
}

.why-description{
  font-size: 14px;
  color: black;
}

.input-border{
  border: none;
  width: 100%;
}


.input-border:focus{
  outline: none;
  width: 100%;
}
