html,
body {
  margin: 0px;
  padding: 0px;
}

* {
  font-family: "Poppins" !important;
}

.background-primary {
  background-color: #06bed8 !important;
}

.background-primary-light {
  background-color: #a7eaf3 !important;
}

.background-secondary {
  background-color: #bfbfbf4d !important;
}

.no-background {
  background-color: transparent !important;
}

.black-color {
  color: #000000 !important; 
}

.black-color:hover {
  color: #000000 !important; 
}

.no-border {
  border: 0px !important;
}

.color-primary,
.color-primary:hover {
  color: #06bed8 !important;
}

.color-secondary {
  color: #929292 !important;
}

.border-color-primary {
  border-color: #06bed8 !important;
}

.font-family-poppins {
  font-family: "Poppins" !important;
}

.font-family-poppins-bold {
  font-family: "Poppins-Bold" !important;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.63);
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.w-85 {
  width: 85%;
}

.font-14{
  font-size:14px;
}

.min-height-vh-75{
  min-height: 75vh;
}

.max-height-vh-50{
  max-height: 50vh;
}

.font-12{
  font-size:12px;
}

.font-16{
  font-size:16px;
}